<template>
  <div id="app">
    <div class="header d-none d-md-flex">
      <div id="main-menu" class="nav">
        <ul>
          <li class="menu-item">
            <a href="#why_amigo">Why A.MIGO! </a>
          </li>
          <li class="menu-item">
            <a href="#our_clients">Our Clients</a>
          </li>
          <li class="menu-item">
            <a href="#features">Features</a>
          </li>
          <li class="menu-item">
            <a href="#faqs">FAQs</a>
          </li>
        </ul>
      </div>

      <div class="logo btt">
        <img src="@/assets/img/logo.svg" alt="">
      </div>

      <a 
      @click.stop.prevent="goToContactUs('https://api.whatsapp.com/send?phone=6593396377')"
      href="#" target="_blank" class="btn">Contact Us</a>
    </div>

    <div class="m_header d-md-none">
      <div class="btn_m_menu">
      </div>

      <div class="logo btt">
        <img src="@/assets/img/logo.svg" alt="">
      </div>

      <a 
      @click.stop.prevent="goToContactUs('https://api.whatsapp.com/send?phone=6593396377')"
      href="#" target="_blank" class="btn btn-small">Contact Us</a>
      

      <div id="mobile-menu" class="m_nav">
        <ul>
          <li class="menu-item">
            <a href="#why_amigo">Why A.MIGO! </a>
          </li>
          <li class="menu-item">
            <a href="#our_clients">Our Clients</a>
          </li>
          <li class="menu-item">
            <a href="#features">Features</a>
          </li>
          <li class="menu-item">
            <a href="#faqs">FAQs</a>
          </li>
        </ul>
      </div>
    </div>

    <Hero/>
    <Why/>
    <Help/>
    <Client/>
    <Cards/>
    <Faq/>
    <Cta/>
    <Footer/>

    <div class="btn_btt">
      <span class="">Back to Top</span>

      <div class="button">
        <img src="@/assets/img/Asset_39.svg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import scrollSpy from 'simple-scrollspy'
import $ from 'jquery'
import Hero from './components/Hero.vue'
import Why from './components/Why.vue'
import Help from './components/Help.vue'
import Client from './components/Client.vue'
import Cards from './components/Cards.vue'
import Faq from './components/Faq.vue'
import Cta from './components/Cta.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Hero,
    Why,
    Help,
    Client,
    Cards,
    Faq,
    Cta,
    Footer
  },
  mounted() {
    $('.btn_m_menu').click(function(){
      if ($(this).hasClass('open')) {
        $(this).removeClass('open')
        $('.m_nav').slideUp(200)
      } else {
        $(this).addClass('open')
        $('.m_nav').slideDown(200)
      }
    })

    $(window).scroll(function(){
      // console.log('asd');
      if ($(this).scrollTop() > 430) {
        if (!$('.header').hasClass('scrolled')) {
          $('.header').addClass('scrolled')
        }

        $('.btn_btt').addClass('scrolled')
        
      } else {
        $('.header').removeClass('scrolled')
        $('.btn_btt').removeClass('scrolled')
      }

      if ($(this).scrollTop() > 300) {
        if (!$('.m_header').hasClass('scrolled')) {
          $('.m_header').addClass('scrolled')
        }

        
      } else {
        $('.m_header').removeClass('scrolled')
      }

      if ($('.m_nav').is(':visible')) {
        $('.m_nav').slideUp(200)
        $('.btn_m_menu').removeClass('open')
      }
    }).scroll()

    $('.btn_btt, .btt').click(function(){
      $('#why_amigo')[0].scrollIntoView({
        behavior: 'smooth',
      });
    })

    $('.nav a, .m_nav a').click(function(e){
      e.preventDefault()
      var target = $(this).attr('href')
      $(target)[0].scrollIntoView({
        behavior: 'smooth',
      });

      $('.m_nav').slideUp(200)
      $('.btn_m_menu').removeClass('open')
    })

    scrollSpy('#main-menu', {
      sectionClass: '.section',
      offset: 200,
    })
    scrollSpy('#mobile-menu', {
      sectionClass: '.section',
      offset: 200,
    })


    // event tracking for buttons
    

    // this.$gtag.event('Button Click', {
    //   'event_category': 'CTA',
    //   'event_label': 'Find out more button'
    // })

    // this.$gtag.event('Button Click', {
    //   'event_category': 'CTA',
    //   'event_label': 'Contact Us'
    // })
  },
  methods: {
    goToContactUs(url) {
      this.$gtag.event('Button Click', {
        'event_category': 'CTA',
        'event_label': 'Contact Us @ Header'
      })

      this.$gtag.event('conversion', {
        'send_to': 'AW-10899494983/R1IjCMfo0rsDEMewpM0o',
        'event_callback': (()=>{
          if (typeof(url) != 'undefined') {
            window.location = url;
          }
        })
      })

      // window.open(url, '_blank');
    }
  }
}
</script>
