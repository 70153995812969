<template>
  <div class="cta section">
    <div class="cta_t">
      <img src="@/assets/img/Asset_40.svg" alt="">
    </div>
    <div class="cta_b">
      <img src="@/assets/img/Asset_26.svg" alt="">

      <img class="s1" src="@/assets/img/Asset_42.svg" alt="">
      <img class="s2" src="@/assets/img/Asset_42.svg" alt="">
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          
        </div>
        <div class="col-md-6">
          <h1>Get Started with A.MIGO</h1>
          <div class="text">
            <p>Speak to our friendly consultants to help you bring your business to the next level.</p>
            <p>Contact us today!</p>
          </div>
          <div class="action">
            <a 
            @click.stop.prevent="goToContactUs('https://api.whatsapp.com/send?phone=6593396377')"
            href="#" target="_blank" class="btn">Contact Us</a>
          </div>
        </div>
      </div>
    </div>

    
  </div>
</template>

<script>
import ScrollReveal from 'scrollreveal'
export default {
  name: "CTA",
  mounted() {
    setTimeout(()=>{
      ScrollReveal().reveal('.cta_b',{
        origin: 'bottom',
        distance: '100px',
        duration: 1600,
        delay: 100,
        // reset: true
      })
    },50)
    
  },
  methods: {
    goToContactUs(url) {
      this.$gtag.event('Button Click', {
        'event_category': 'CTA',
        'event_label': 'Contact Us @ Footer'
      })

      this.$gtag.event('conversion', {
        'send_to': 'AW-10899494983/R1IjCMfo0rsDEMewpM0o',
        'event_callback': (()=>{
          if (typeof(url) != 'undefined') {
            window.location = url;
          }
        })
      })

      // window.open(url, '_blank');
    }
  }
}
</script>