<template>
  <div id="our_clients" class="client section">
    <div class="client_t">
      <img src="@/assets/img/Asset_34.svg" alt="">
    </div>

    <div class="client_box container">
      <h1 class="section_title">Our Clients</h1>

      <div class="cl_swiper">
        <div class="i1">
          <img src="@/assets/img/Asset_33.svg" alt="">
        </div>
        <div class="btn_next">
          <img src="@/assets/img/Asset_8.svg" alt="">
        </div>
        <div class="btn_prev">
          <img src="@/assets/img/Asset_9.svg" alt="">
        </div>
        <div class="swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="cl_slide">
                <div class="logos">
                  <img class="d-none d-md-block" src="@/assets/img/Asset_44.png" alt="">
                  <img class="d-block d-md-none" src="@/assets/img/Asset_45.png" alt="">
                </div>
                
              </div>
            </div>
            <!-- slide -->
            <div class="swiper-slide">
              <div class="cl_slide">
                <div class="logo">
                  <img src="@/assets/img/Asset_15.png" alt="">
                </div>
                <div class="text">
                  “We have been using their loyalty and rewards solution for the past 4 years and have always been very satisfied with the availability, consistency and stability of the platform. This is particularly important for us as our business leverage on the many marketing capabilities that Ascentis CRM provides to outreach and engage our customers.”

                  <div class="from">Crystal Jade</div>
                </div>
              </div>
            </div>
            <!-- slide -->
            <div class="swiper-slide">
              <div class="cl_slide">
                <div class="logo">
                  <img src="@/assets/img/vP7IeK.png" alt="">
                </div>
                <div class="text">
                  “In the last 2 years, Ascentis’ CRM solution has been tried and proven to be stable and consistent in enabling us in effective CRM marketing. The local Ascentis support team is prompt, professional and reliable; their excellent track record coupled with local food & beverage CRM knowledge is a plus unmatched by any other CRM solution provider.”

                  <div class="from">Jumbo Group</div>
                </div>
              </div>
            </div>
            <!-- slide -->
            
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
      <!-- swiper -->
    </div>
  </div>
</template>
<script>
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper.scss';
import ScrollReveal from 'scrollreveal'
export default {
  name: "Client",
  data() {
    return {
      swiper: null
    }
  },
  mounted() {
    this.swiper = new Swiper('.cl_swiper .swiper', {
      // Install modules
      modules: [Navigation, Pagination],
      speed: 500,
      spaceBetween: 100,
      observer: true,
      observeParents: true,
      autoHeight: true,
      loop: true,
      autoplay: {
        delay: 8000,
      },
      navigation: {
        nextEl: '.cl_swiper .btn_next',
        prevEl: '.cl_swiper .btn_prev',
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
    });

    setTimeout(()=>{
      ScrollReveal().reveal('.cl_swiper .i1',{
        // origin: 'bottom',
        scale: 0,
        distance: '100px',
        duration: 600,
        delay: 300,
        // reset: true
      })
    },50)
  }
}
</script>