<template>
  <div id="features" class="cards section">
    <div class="container">
      <h1 class="section_title">
        A.MIGO’s got your back covered.
      </h1>

      <div class="cs_swiper">
        <div class="btn_next">
          <img src="@/assets/img/Asset_37.svg" alt="">
        </div>
        <div class="btn_prev">
          <img src="@/assets/img/Asset_36.svg" alt="">
        </div>

        <div class="swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="cs_cell">
                <div class="icon">
                  <img src="@/assets/img/Asset_35.svg" alt="">
                </div>
                <div class="content">
                  <h2 class="title">
                    Realtime business intelligence
                  </h2>
                  <div class="text">
                    Get instant intelligence on your sales performance real-time. No more second guessing.
                  </div>
                </div>
              </div>
            </div>
            <!-- swiper -->

            <div class="swiper-slide">
              <div class="cs_cell">
                <div class="icon">
                  <img src="@/assets/img/Asset_20.svg" alt="">
                </div>
                <div class="content">
                  <h2 class="title">
                    Instant Rewards for all
                  </h2>
                  <div class="text">
                    Win over competition with the right offers at the right time.
                  </div>
                </div>
              </div>
            </div>
            <!-- swiper -->

            <div class="swiper-slide">
              <div class="cs_cell">
                <div class="icon">
                  <img src="@/assets/img/influence-feedback-testimonials-user report.svg" alt="">
                </div>
                <div class="content">
                  <h2 class="title">
                    Trusted and validated by brands and partners
                  </h2>
                  <div class="text">
                    Trusted by familiar brands in Singapore - From Starbucks, Crystal Jade and Breadtalk Group to Changi Airport and ION Orchard.
                  </div>
                </div>
              </div>
            </div>
            <!-- swiper -->

            <div class="swiper-slide">
              <div class="cs_cell">
                <div class="icon">
                  <img src="@/assets/img/Asset_18.svg" alt="">
                </div>
                <div class="content">
                  <h2 class="title">
                    Effective customer engagement
                  </h2>
                  <div class="text">
                    Create a 360-degree engagement in-store and off-line with in-built marketing tools.
                  </div>
                </div>
              </div>
            </div>
            <!-- swiper -->

            <div class="swiper-slide">
              <div class="cs_cell">
                <div class="icon">
                  <img src="@/assets/img/responsive_design.svg" alt="">
                </div>
                <div class="content">
                  <h2 class="title">
                    Hassle free programme
                  </h2>
                  <div class="text">
                    Skip the need for POS integration or the use of a mobile app.
                  </div>
                </div>
              </div>
            </div>
            <!-- swiper -->

            <div class="swiper-slide">
              <div class="cs_cell">
                <div class="icon">
                  <img src="@/assets/img/Asset_22.svg" alt="">
                </div>
                <div class="content">
                  <h2 class="title">
                    PDPA Compliant
                  </h2>
                  <div class="text">
                    In compliance with the Personal Data Protection Act (PDPA).
                  </div>
                </div>
              </div>
            </div>
            <!-- swiper -->

            <div class="swiper-slide">
              <div class="cs_cell">
                <div class="icon">
                  <img src="@/assets/img/Asset_24.svg" alt="">
                </div>
                <div class="content">
                  <h2 class="title">
                    Business as usual; <br>no downtime!
                  </h2>
                  <div class="text">
                    just 2 weeks to get your loyalty program set up and going.
                  </div>
                </div>
              </div>
            </div>
            <!-- swiper -->

            <div class="swiper-slide">
              <div class="cs_cell">
                <div class="icon">
                  <img src="@/assets/img/Asset_23.svg" alt="">
                </div>
                <div class="content">
                  <h2 class="title">
                    Tailored for your business
                  </h2>
                  <div class="text">
                    every business is unique, so should your crm! choose between offering points or rebates as part of your loyalty program, and we will handle the rest.
                  </div>
                </div>
              </div>
            </div>
            <!-- swiper -->

          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper, { Navigation, Pagination, EffectCoverflow } from 'swiper';
import 'swiper/swiper.scss';
export default {
  name: "Cards",
  data() {
    return {
      swiper: null
    }
  },
  mounted() {
    this.swiper = new Swiper('.cs_swiper .swiper', {
      // Install modules
      modules: [Navigation, Pagination, EffectCoverflow],
      speed: 500,
      // spaceBetween: 100,
      observer: true,
      observeParents: true,
      // autoHeight: true,
      navigation: {
        nextEl: '.cs_swiper .btn_next',
        prevEl: '.cs_swiper .btn_prev',
      },
      pagination: {
        el: '.cs_swiper .swiper-pagination',
        type: 'bullets',
      },
      effect: "coverflow",
      grabCursor: true,
      loop: true,
      centeredSlides: true,
      slidesPerView: 1,
      initialSlide: 2,
      autoplay: {
        delay: 8000,
      },
      coverflowEffect: {
        rotate: 0,
        stretch: 100,
        depth: 100,
        modifier: 1,
        slideShadows: false
      },
      breakpoints: {
        576: {
          slidesPerView: 3,
          coverflowEffect: {
            rotate: 0,
            stretch: 100,
            depth: 100,
            modifier: 1,
            slideShadows: false
          },
        },
        768: {
          slidesPerView: 3,
          coverflowEffect: {
            rotate: 0,
            stretch: 100,
            depth: 100,
            modifier: 1,
            slideShadows: false
          },
        }
      }
    });
  }
}
</script>