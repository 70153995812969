import Vue from 'vue'
import App from './App.vue'
import "normalize.css/normalize.css";
import "bootstrap/dist/css/bootstrap-grid.css";
import "@/assets/css/global.scss";

Vue.config.productionTip = false

import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "G-GL0Z102MYX" },
  includes: [
    { id: 'AW-10899494983' }
  ]
});

new Vue({
  render: h => h(App),
}).$mount('#app')
