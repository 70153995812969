<template>
  <div id="faqs" class="faq section">
    <div class="faq_t">
      <img src="@/assets/img/Asset_38.svg" alt="">
    </div>
    <div class="container">
      <h1 class="section_title">
        FAQs
      </h1>

      <div class="faq_i1">
        <img src="@/assets/img/Asset_25.svg" alt="">
      </div>

      <div class="faq_swiper">

        <div class="btn_next">
          <img src="@/assets/img/Asset_8.svg" alt="">
        </div>
        <div class="btn_prev">
          <img src="@/assets/img/Asset_9.svg" alt="">
        </div>

        <div class="swiper">
          <div class="swiper-wrapper">
            <template
            v-for="faq in faqs"
            >
              <div 
              :key="faq.id"
              class="swiper-slide">
                <div 
                
                class="q">
                  <div 
                  v-html="faq.q"
                  class="q_text"></div>
                </div>
              </div>
            </template>
            <!-- slide -->
          </div>
          <!-- <div class="swiper-pagination"></div> -->
        </div>
      </div>
      <!-- swiper -->

      <div 
      v-html="answer"
      class="answer">

      </div>
      
    </div>

    
    
  </div>
</template>

<script>
import ScrollReveal from 'scrollreveal'
import Swiper, { Navigation, Pagination, EffectCoverflow } from 'swiper';
import 'swiper/swiper.scss';
export default {
  name: "Cards",
  computed: {
    answer() {
      if (this.swiper) {
        return this.faqs[this.swiper.realIndex].a
      } else {
        return ''
      }
      
    }
  },
  data() {
    return {
      swiper: null,
      faqs: [
        {
          id: 1,
          q: `How long does it take to set up my loyalty programme?`,
          a: `Two weeks* is all it takes to set up your very own loyalty programme.
          <br><br>
          <sub>*Two weeks does not include the requirements gathering process.</sub>
          
          `
        },
        {
          id: 2,
          q: `Is there guidance on setting up the rewards/campaigns mechanics?`,
          a: `We have curated in-built rewards campaign mechanics that are widely adopted by F&B and retail businesses. Thus, setting up your rewards campaign will be quick and fuss-free.`
        },
        {
          id: 3,
          q: `What are the charges like to use A.MIGO?`,
          a: `There will be monthly subscription fees plus a one-time set up fees to use A.MIGO and It’s suite of features. Subscription costs as low as $9.90 a day. Speak to us today to find out more!`
        },
        {
          id: 4,
          q: `Do I need additional hardware to operate?`,
          a: `A.MIGO runs on cloud and can be operated as long as there is broadband connectivity / high speed internet available in your store.`
        },
        {
          id: 5,
          q: `Is there a limit on the number of communications (eDM / SMS) that I can set up on A.MIGO?`,
          a: `Absolutely not! There are no limits to the number of communications that you can set up on A.MIGO. However, prevailing telco charges apply for all SMS transmission. Speak to us today to find out more.`
        },
        {
          id: 6,
          q: `Does A.MIGO comes with reporting features?`,
          a: `Yes. A.MIGO comes with a suite of ready to use reporting templates to help you gather all the insights that you may need for your business.`
        },
        {
          id: 7,
          q: `Is this system PDPA compliance?`,
          a: `Yes!`
        },
        {
          id: 8,
          q: `Is there a limited number of stores and members for this system?`,
          a: `There are no limits to the number of stores or members that you can recruit for your loyalty programme. However, there will be licensing fees for each additional store that you set up on A.MIGO.`
        },
        {
          id: 9,
          q: `Can a member access his/her own profile?`,
          a: `A.MIGO comes with a mobile responsive members’ portal that allows your members to browse their transactions and rewards, update their profiles and view upcoming promotions. 
          Speak to us to request for a demo today.`
        },
      ]
    }
  },
  mounted() {

    setTimeout(()=>{
      ScrollReveal().reveal('.faq_i1',{
        origin: 'left',
        scale: 0,
        distance: '100px',
        duration: 800,
        delay: 200,
        // reset: true
      })
    },50)
    

    this.swiper = new Swiper('.faq_swiper .swiper', {
      // Install modules
      modules: [Navigation, Pagination, EffectCoverflow],
      speed: 500,
      // spaceBetween: 100,
      observer: true,
      observeParents: true,
      // autoHeight: true,
      navigation: {
        nextEl: '.faq_swiper .btn_next',
        prevEl: '.faq_swiper .btn_prev',
      },
      // pagination: {
      //   el: '.faq_swiper .swiper-pagination',
      //   type: 'bullets',
      // },
      effect: "coverflow",
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: 1,
      initialSlide: 2,
      loop: true,
      autoplay: {
        delay: 15000,
      },
      coverflowEffect: {
        rotate: 0,
        stretch: 290,
        depth: 300,
        modifier: 1,
        slideShadows: false
      },
      breakpoints: {
        576: {
          effect: "coverflow",
          slidesPerView: 3,
          coverflowEffect: {
            rotate: 0,
            stretch: 30,
            depth: 300,
            modifier: 1,
            slideShadows: false
          },
        },
        1200: {
          effect: "coverflow",
          slidesPerView: 3,
          coverflowEffect: {
            rotate: 0,
            stretch: 30,
            depth: 200,
            modifier: 1,
            slideShadows: false
          },
        }
      }
    });
  }
}
</script>