<template>
  <div class="help section">
    <!-- <div class="help_t">
      <img src="@/assets/img/Asset_31.svg" alt="">
    </div> -->

    <div class="container">
      <h1 class="section_title">A.MIGO Will Help You</h1>
      <div class="help_c">
        <div class="row">
          <div class="col-md-6 order-md-2">
            <div class="img h_img_1">
              <img src="@/assets/img/Asset_11.svg" alt="">
              <img class="like" src="@/assets/img/like.svg" alt="">
            </div>
          </div>
          <div class="col-md-6 order-md-1">
            <div class="content">
              <h2 class="title">Create your own loyalty campaign</h2>
              <div class="text">
                <p>Based on our award-winning platform and solution, A.MIGO is packed with in-built loyalty mechanics* that’s ready to go!</p>
                <p>Run campaigns on your POS and track the results instantly.</p>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <!-- row -->
      <div class="help_c">
        <div class="row">
          <div class="col-md-6 ">
            <div class="img h_img_2">
              <img src="@/assets/img/Asset_12.svg" alt="">
              <img class="letter" src="@/assets/img/letter.svg" alt="">
            </div>
          </div>
          <div class="col-md-6">
            <div class="content">
              <h2 class="title">Engage your customers with our<br class="d-none d-sm-block"> in-built marketing tools.</h2>
              <div class="text">
                <p>Never miss an opportunity to connect with customers when it matters, so you can turn customers into loyal customers.</p>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <!-- row -->
      <div class="help_c">
        <div class="row">
          <div class="col-md-6 order-md-2">
            <div class="img h_img_3">
              <img src="@/assets/img/Asset_13.svg" alt="">
              <img class="gear" src="@/assets/img/gear.svg" alt="">
              
            </div>
          </div>
          <div class="col-md-6 order-md-1">
            <div class="content">
              <h2 class="title">Gain customer insights<br class="d-none d-sm-block"> for your next move.</h2>
              <div class="text">
                <p>Make smarter decisions for your business with our wide range of reports.</p>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <!-- row -->

      <div class="help_action">
          <a 
          @click.stop.prevent="goToFindOutMore('https://api.whatsapp.com/send?phone=6593396377')"
          href="#" target="_blank" class="btn btn-large">Find Out More</a>
        <!-- <a href="https://api.whatsapp.com/send?phone=6593396377" target="_blank" class="btn btn-large">Find Out More</a> -->
      </div>
      
    </div>
    

  </div>
</template>

<script>
import ScrollReveal from 'scrollreveal'
export default {
  name: "Help",
  mounted() {
    setTimeout(()=>{
      ScrollReveal().reveal('.h_img_1',{
        origin: 'right',
        distance: '100px',
        duration: 800,
        delay: 100,
        // reset: true
      })
      ScrollReveal().reveal('.h_img_2',{
        origin: 'left',
        distance: '100px',
        duration: 800,
        delay: 100,
        // reset: true
      })
      ScrollReveal().reveal('.h_img_3',{
        origin: 'right',
        distance: '100px',
        duration: 800,
        delay: 100,
        // reset: true
      })
    },50) 
  },
  methods: {
    goToFindOutMore(url) {
      this.$gtag.event('Button Click', {
        'event_category': 'CTA',
        'event_label': 'Find Out More Button'
      })

      this.$gtag.event('conversion', {
        'send_to': 'AW-10899494983/R1IjCMfo0rsDEMewpM0o',
        'event_callback': (()=>{
          if (typeof(url) != 'undefined') {
            window.location = url;
          }
        })
      })

      // window.open(url, '_blank');
    }
  }
}
</script>