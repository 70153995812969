<template>
  <div id="why_amigo" class="hero section">
    <div class="hero_kv">
      <img src="@/assets/img/Asset_3.svg" alt="">
    </div>
    
    <h1 class="hero_title">
      Are you ready to pivot your business?
    </h1>
    <div class="hero_text">
      You’re in need of a trusted sidekick. <br class="d-sm-none">Introducing Hey A.MIGO!<br>Our award-winning ready-to-go loyalty platform that helps you quickly roll out a loyalty programme for your business.
    </div>
    <div class="hero_action">

      <!-- <a 
      @click.stop.prevent="goToLearnMore('https://api.whatsapp.com/send?phone=6593396377')"
      href="#" target="_blank" class="btn">Learn More</a> -->
      <a 
      @click.stop.prevent="goToWhy()"
      href="#" target="_blank" class="btn">Learn More</a>
    </div>

    <div class="hero_b">
      <img src="@/assets/img/Asset_27.svg" alt="">
    </div>

    <div class="hero_i2">
      <img src="@/assets/img/Asset_2.svg" alt="">
      <img class="s1" src="@/assets/img/Asset_42.svg" alt="">
      <img class="s2" src="@/assets/img/Asset_42.svg" alt="">
    </div>


    <div class="hero_i1">
      <img src="@/assets/img/Asset_4.svg" alt="">
      <img class="heart" src="@/assets/img/Asset_41.svg" alt="">
    </div>
    
  </div>
</template>

<script>
import $ from 'jquery'
import ScrollReveal from 'scrollreveal'
export default {
  name:"Hero",
  mounted() {
    setTimeout(()=>{
      ScrollReveal().reveal('.hero_i2',{
        origin: 'right',
        distance: '100px',
        duration: 800,
        delay: 100,
        // reset: true
      })
      ScrollReveal().reveal('.hero_i1',{
        origin: 'left',
        distance: '100px',
        duration: 800,
        delay: 100,
        // reset: true
      })
      ScrollReveal().reveal('.heart',{
        origin: 'left',
        distance: '100px',
        duration: 800,
        delay: 100,
        // reset: true
      })
    },50)
    
  },
  methods: {
    goToLearnMore(url) {
      this.$gtag.event('Button Click', {
        'event_category': 'CTA',
        'event_label': 'Learn More Button'
      })

      this.$gtag.event('conversion', {
        'send_to': 'AW-10899494983/R1IjCMfo0rsDEMewpM0o',
        'event_callback': (()=>{
          if (typeof(url) != 'undefined') {
            window.location = url;
          }
        })
      })

      // window.open(url, '_blank');
    },
    goToWhy() {
      $('.why')[0].scrollIntoView({
        behavior: 'smooth',
      })
    }
  }
}
</script>