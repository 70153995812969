<template>
  <div class="why section">
    <div class="why_m">
      <img src="@/assets/img/Asset_31.svg" alt="">
    </div>
    <div class="why_b">

    </div>
    <div class="container">
      <h1 class="section_title">Why A.MIGO</h1>

      <div class="why_swiper">
        <div class="btn_next">
          <img src="@/assets/img/Asset_8.svg" alt="">
        </div>
        <div class="btn_prev">
          <img src="@/assets/img/Asset_9.svg" alt="">
        </div>
        <div class="swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="why_slide">
                <div class="row">
                  <div class="col-md-6">
                    <div class="img">
                      <img src="@/assets/img/Asset_5.svg" alt="">
                    </div>
                  </div>
                  <!-- left -->
                  <div class="col-md-6">
                    <div class="content">
                      <h2>Get MORE sales and PIVOT your business with A.MIGO</h2>
                      <div class="row two_col">
                        <div class="e">
                          <img src="@/assets/img/Asset_28.svg" alt="">
                        </div>
                        <div class="col-6">
                          <div class="c">
                            <img src="@/assets/img/Asset_6.svg" alt="">
                            <div class="c_text">
                              With every <b>2 additional customers</b> who spend $20 daily
                            </div>
                          </div>
                          
                        </div>
                        <div class="col-6">
                          <div class="c">
                            <!-- <img src="@/assets/img/Asset_7.svg" alt=""> -->

                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140.07 153.23">
                              <g id="a1d7cef5-9f4d-4616-b564-9ea32693b0ab" data-name="Layer 2">
                                <g id="bee72317-2c04-428a-826d-7d8c1e5dc675" data-name="Layer 1">
                                  <polyline class="a55f6711-0758-4d62-a17f-8e1543bd7eb2"
                                    points="98.55 56.84 98.55 37.29 86.33 37.29 110.78 3.05 135.24 37.29 123.01 37.29 123.07 56.77" fill="#ec1c24" />
                                  <ellipse class="b2ac90ee-6a33-4a44-a904-99849d765624" fill="#ffc200" cx="86.53" cy="109.59" rx="23.09" ry="9.9" />
                                  <path class="b2ac90ee-6a33-4a44-a904-99849d765624" fill="#ffc200"
                                    d="M109.62,109.59v16.5c0,5.46-10.34,9.9-23.09,9.9s-23.1-4.44-23.1-9.9v-16.5" />
                                  <path class="b2ac90ee-6a33-4a44-a904-99849d765624" fill="#ffc200"
                                    d="M63.43,126.09v16.49c0,5.47,10.34,9.9,23.1,9.9s23.09-4.43,23.09-9.9V126.09" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M49.42,136.74c-.76,0-1.52,0-2.27,0a.78.78,0,0,1-.21,0C20.19,136.74,0,119.52,0,96.69,0,77.51,20.56,55.55,39.81,36.48a.75.75,0,1,1,1.06,1.06C21.83,56.41,1.52,78.09,1.5,96.69c0,21.91,19.44,38.45,45.22,38.54a.67.67,0,0,1,.24,0,64.21,64.21,0,0,0,6.8-.11.75.75,0,1,1,.12,1.49C52.41,136.68,50.92,136.74,49.42,136.74Z" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M84.26,73.06a.74.74,0,0,1-.63-.34C76,60.86,64.38,48.81,53,37.54a.75.75,0,0,1,0-1.06.77.77,0,0,1,1.07,0C65.49,47.8,77.13,59.93,84.89,71.9a.76.76,0,0,1-.23,1A.72.72,0,0,1,84.26,73.06Z" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M53.54,37.76a.69.69,0,0,1-.43-.14.75.75,0,0,1-.19-1C58.41,28.76,66.83,16,68,12.21A79.51,79.51,0,0,1,43.44,9.52c-8-2.29-16.19-3.87-20.21,3.91,1,2.11,11.25,15.14,17.69,23.11a.75.75,0,0,1-1.16.94c-18.68-23.09-18.25-24-18-24.47,4.49-9.09,13.76-7.31,22.1-4.93a78.31,78.31,0,0,0,24.72,2.61h.25a.74.74,0,0,1,.79.7c.2,3.56-12.85,22.33-15.47,26.06A.75.75,0,0,1,53.54,37.76Z" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M46.94,37.76H33.74a.75.75,0,0,1,0-1.5h13.2a.75.75,0,0,1,0,1.5Z" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M60.13,37.76H46.94a.75.75,0,0,1,0-1.5H60.13a.75.75,0,0,1,0,1.5Z" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M86.53,120.24c-13.37,0-23.85-4.68-23.85-10.65S73.16,98.94,86.53,98.94s23.84,4.68,23.84,10.65S99.9,120.24,86.53,120.24Zm0-19.8c-12.11,0-22.35,4.19-22.35,9.15s10.24,9.15,22.35,9.15,22.34-4.19,22.34-9.15S98.64,100.44,86.53,100.44Z" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M86.53,136.74c-13.37,0-23.85-4.68-23.85-10.65v-16.5a.75.75,0,0,1,1.5,0v16.5c0,5,10.24,9.15,22.35,9.15s22.34-4.19,22.34-9.15v-16.5a.75.75,0,0,1,1.5,0v16.5C110.37,132.06,99.9,136.74,86.53,136.74Z" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M86.53,153.23c-13.37,0-23.85-4.68-23.85-10.65V126.09a.75.75,0,0,1,1.5,0v16.49c0,5,10.24,9.15,22.35,9.15s22.34-4.19,22.34-9.15V126.09a.75.75,0,0,1,1.5,0v16.49C110.37,148.55,99.9,153.23,86.53,153.23Z" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M119.52,120.14a.75.75,0,0,1,0-1.5c10.7-.66,19.09-4.63,19.09-9V93.1a.75.75,0,0,1,1.5,0v16.49c0,5.38-8.62,9.82-20.5,10.55Z" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M116.22,87.25c-13.37,0-23.84-4.68-23.84-10.65S102.85,66,116.22,66s23.85,4.68,23.85,10.65S129.59,87.25,116.22,87.25Zm0-19.8c-12.11,0-22.34,4.19-22.34,9.15s10.23,9.15,22.34,9.15,22.35-4.19,22.35-9.15S128.33,67.45,116.22,67.45Z" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M93.13,90.55a.75.75,0,0,1-.75-.75V76.6a.75.75,0,0,1,1.5,0V89.8A.76.76,0,0,1,93.13,90.55Z" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M118.28,103.7a.75.75,0,0,1,0-1.49c11.39-.44,20.32-4.44,20.32-9.11V76.6a.75.75,0,0,1,1.5,0V93.1c0,5.57-9.36,10.13-21.76,10.6Z" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M119.52,136.64a.75.75,0,0,1,0-1.5c10.71-.66,19.1-4.63,19.1-9v-16.5a.75.75,0,0,1,1.5,0v16.5c0,5.38-8.63,9.82-20.51,10.54Z" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M50.24,103.74H43.71a7.68,7.68,0,0,1-7.42-7.34.75.75,0,0,1,1.5,0,6.2,6.2,0,0,0,5.92,5.84h6.53a6.08,6.08,0,0,0,5.85-5.84v-1a5.26,5.26,0,0,0-4-4.92L41.36,87.25a6.78,6.78,0,0,1-5.07-6.35v-1a7.64,7.64,0,0,1,7.35-7.35h6.6a7.64,7.64,0,0,1,7.35,7.35.75.75,0,0,1-1.5,0,6.09,6.09,0,0,0-5.85-5.85h-6.6a6.09,6.09,0,0,0-5.85,5.85v1a5.28,5.28,0,0,0,4,4.91L52.58,89a6.76,6.76,0,0,1,5,6.36v1A7.64,7.64,0,0,1,50.24,103.74Z" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M46.94,74.05a.75.75,0,0,1-.75-.75V66.7a.75.75,0,0,1,1.5,0v6.6A.76.76,0,0,1,46.94,74.05Z" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M46.94,110.34a.74.74,0,0,1-.75-.75V103a.75.75,0,0,1,1.5,0v6.6A.75.75,0,0,1,46.94,110.34Z" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M119.52,57.55a.75.75,0,0,1-.75-.74L118.7,38a.75.75,0,0,1,.22-.53.72.72,0,0,1,.53-.23h11.84L106.15,2,81,37.23H92.85a.75.75,0,0,1,.75.75v10.6a.75.75,0,1,1-1.5,0V38.73H79.56A.74.74,0,0,1,79,37.55L105.54.31a.77.77,0,0,1,.61-.31h0a.77.77,0,0,1,.61.31l26.6,37.24a.78.78,0,0,1,.06.78.75.75,0,0,1-.67.4H120.2l.07,18.07a.74.74,0,0,1-.75.75Z" />
                                </g>
                              </g>
                            </svg>
                            <div class="c_text">
                              That’s an increase of <b>$1,200*</b> in monthly revenue!
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- 2col -->

                      <div class="eg">
                        <b>Example:</b> <br>
                        Drive <b>more sales</b> by running an email marketing campaign to promote your promotions to your customers.
                      </div>
                    </div>
                  </div>
                  <!-- right -->
                </div>
              </div>
              
            </div>
            <!-- slide -->

            <div class="swiper-slide">
              <div class="why_slide">
                <div class="row">
                  <div class="col-md-6">
                    <div class="img">
                      <img src="@/assets/img/Asset_29.svg" alt="">
                    </div>
                  </div>
                  <!-- left -->
                  <div class="col-md-6">
                    <div class="content">
                      <h2>Drive MORE visits with <br>digital vouchers</h2>
                      <div class="row two_col">
                        <div class="e">
                          <img src="@/assets/img/Asset_28.svg" alt="">
                        </div>
                        <div class="col-6">
                          <div class="c">
                            <img src="@/assets/img/Asset_30.svg" alt="">
                            <div class="c_text">
                              <b>5 customers</b> spend <br><b>$5 more</b> per bill
                            </div>
                          </div>
                          
                        </div>
                        <div class="col-6">
                          <div class="c">
                            <!-- <img src="@/assets/img/Asset_7.svg" alt=""> -->
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140.07 153.23">
                              <g id="a1d7cef5-9f4d-4616-b564-9ea32693b0ab" data-name="Layer 2">
                                <g id="bee72317-2c04-428a-826d-7d8c1e5dc675" data-name="Layer 1">
                                  <polyline class="a55f6711-0758-4d62-a17f-8e1543bd7eb2"
                                    points="98.55 56.84 98.55 37.29 86.33 37.29 110.78 3.05 135.24 37.29 123.01 37.29 123.07 56.77" fill="#ec1c24" />
                                  <ellipse class="b2ac90ee-6a33-4a44-a904-99849d765624" fill="#ffc200" cx="86.53" cy="109.59" rx="23.09" ry="9.9" />
                                  <path class="b2ac90ee-6a33-4a44-a904-99849d765624" fill="#ffc200"
                                    d="M109.62,109.59v16.5c0,5.46-10.34,9.9-23.09,9.9s-23.1-4.44-23.1-9.9v-16.5" />
                                  <path class="b2ac90ee-6a33-4a44-a904-99849d765624" fill="#ffc200"
                                    d="M63.43,126.09v16.49c0,5.47,10.34,9.9,23.1,9.9s23.09-4.43,23.09-9.9V126.09" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M49.42,136.74c-.76,0-1.52,0-2.27,0a.78.78,0,0,1-.21,0C20.19,136.74,0,119.52,0,96.69,0,77.51,20.56,55.55,39.81,36.48a.75.75,0,1,1,1.06,1.06C21.83,56.41,1.52,78.09,1.5,96.69c0,21.91,19.44,38.45,45.22,38.54a.67.67,0,0,1,.24,0,64.21,64.21,0,0,0,6.8-.11.75.75,0,1,1,.12,1.49C52.41,136.68,50.92,136.74,49.42,136.74Z" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M84.26,73.06a.74.74,0,0,1-.63-.34C76,60.86,64.38,48.81,53,37.54a.75.75,0,0,1,0-1.06.77.77,0,0,1,1.07,0C65.49,47.8,77.13,59.93,84.89,71.9a.76.76,0,0,1-.23,1A.72.72,0,0,1,84.26,73.06Z" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M53.54,37.76a.69.69,0,0,1-.43-.14.75.75,0,0,1-.19-1C58.41,28.76,66.83,16,68,12.21A79.51,79.51,0,0,1,43.44,9.52c-8-2.29-16.19-3.87-20.21,3.91,1,2.11,11.25,15.14,17.69,23.11a.75.75,0,0,1-1.16.94c-18.68-23.09-18.25-24-18-24.47,4.49-9.09,13.76-7.31,22.1-4.93a78.31,78.31,0,0,0,24.72,2.61h.25a.74.74,0,0,1,.79.7c.2,3.56-12.85,22.33-15.47,26.06A.75.75,0,0,1,53.54,37.76Z" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M46.94,37.76H33.74a.75.75,0,0,1,0-1.5h13.2a.75.75,0,0,1,0,1.5Z" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M60.13,37.76H46.94a.75.75,0,0,1,0-1.5H60.13a.75.75,0,0,1,0,1.5Z" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M86.53,120.24c-13.37,0-23.85-4.68-23.85-10.65S73.16,98.94,86.53,98.94s23.84,4.68,23.84,10.65S99.9,120.24,86.53,120.24Zm0-19.8c-12.11,0-22.35,4.19-22.35,9.15s10.24,9.15,22.35,9.15,22.34-4.19,22.34-9.15S98.64,100.44,86.53,100.44Z" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M86.53,136.74c-13.37,0-23.85-4.68-23.85-10.65v-16.5a.75.75,0,0,1,1.5,0v16.5c0,5,10.24,9.15,22.35,9.15s22.34-4.19,22.34-9.15v-16.5a.75.75,0,0,1,1.5,0v16.5C110.37,132.06,99.9,136.74,86.53,136.74Z" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M86.53,153.23c-13.37,0-23.85-4.68-23.85-10.65V126.09a.75.75,0,0,1,1.5,0v16.49c0,5,10.24,9.15,22.35,9.15s22.34-4.19,22.34-9.15V126.09a.75.75,0,0,1,1.5,0v16.49C110.37,148.55,99.9,153.23,86.53,153.23Z" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M119.52,120.14a.75.75,0,0,1,0-1.5c10.7-.66,19.09-4.63,19.09-9V93.1a.75.75,0,0,1,1.5,0v16.49c0,5.38-8.62,9.82-20.5,10.55Z" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M116.22,87.25c-13.37,0-23.84-4.68-23.84-10.65S102.85,66,116.22,66s23.85,4.68,23.85,10.65S129.59,87.25,116.22,87.25Zm0-19.8c-12.11,0-22.34,4.19-22.34,9.15s10.23,9.15,22.34,9.15,22.35-4.19,22.35-9.15S128.33,67.45,116.22,67.45Z" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M93.13,90.55a.75.75,0,0,1-.75-.75V76.6a.75.75,0,0,1,1.5,0V89.8A.76.76,0,0,1,93.13,90.55Z" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M118.28,103.7a.75.75,0,0,1,0-1.49c11.39-.44,20.32-4.44,20.32-9.11V76.6a.75.75,0,0,1,1.5,0V93.1c0,5.57-9.36,10.13-21.76,10.6Z" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M119.52,136.64a.75.75,0,0,1,0-1.5c10.71-.66,19.1-4.63,19.1-9v-16.5a.75.75,0,0,1,1.5,0v16.5c0,5.38-8.63,9.82-20.51,10.54Z" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M50.24,103.74H43.71a7.68,7.68,0,0,1-7.42-7.34.75.75,0,0,1,1.5,0,6.2,6.2,0,0,0,5.92,5.84h6.53a6.08,6.08,0,0,0,5.85-5.84v-1a5.26,5.26,0,0,0-4-4.92L41.36,87.25a6.78,6.78,0,0,1-5.07-6.35v-1a7.64,7.64,0,0,1,7.35-7.35h6.6a7.64,7.64,0,0,1,7.35,7.35.75.75,0,0,1-1.5,0,6.09,6.09,0,0,0-5.85-5.85h-6.6a6.09,6.09,0,0,0-5.85,5.85v1a5.28,5.28,0,0,0,4,4.91L52.58,89a6.76,6.76,0,0,1,5,6.36v1A7.64,7.64,0,0,1,50.24,103.74Z" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M46.94,74.05a.75.75,0,0,1-.75-.75V66.7a.75.75,0,0,1,1.5,0v6.6A.76.76,0,0,1,46.94,74.05Z" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M46.94,110.34a.74.74,0,0,1-.75-.75V103a.75.75,0,0,1,1.5,0v6.6A.75.75,0,0,1,46.94,110.34Z" />
                                  <path class="b2b8a128-5b93-4c24-9ec9-d11b115c8d94" fill="#185ada"
                                    d="M119.52,57.55a.75.75,0,0,1-.75-.74L118.7,38a.75.75,0,0,1,.22-.53.72.72,0,0,1,.53-.23h11.84L106.15,2,81,37.23H92.85a.75.75,0,0,1,.75.75v10.6a.75.75,0,1,1-1.5,0V38.73H79.56A.74.74,0,0,1,79,37.55L105.54.31a.77.77,0,0,1,.61-.31h0a.77.77,0,0,1,.61.31l26.6,37.24a.78.78,0,0,1,.06.78.75.75,0,0,1-.67.4H120.2l.07,18.07a.74.74,0,0,1-.75.75Z" />
                                </g>
                              </g>
                            </svg>
                            <div class="c_text">
                              That’s an increase of <b>$750**</b> in monthly revenue!
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- 2col -->

                      <div class="eg">
                        <b>Example:</b> <br>
                        Easily deploy a spend campaign on A.MIGO that rewards your customers with a <b>$5 return e-voucher*</b> when they spend $25 and more.
                      </div>
                    </div>
                  </div>
                  <!-- right -->
                </div>
              </div>
            </div>
            <!-- slide -->
          </div>

          <div class="swiper-pagination"></div>
          
        </div>
      </div>
    </div>

    
  </div>
</template>
<script>
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper.scss';
export default {
  name: "Why",
  data() {
    return {
      swiper: null
    }
  },
  mounted() {
    this.swiper = new Swiper('.why_swiper .swiper', {
      // Install modules
      modules: [Navigation,Pagination ],
      speed: 500,
      observer: true,
      observeParents: true,
      spaceBetween: 100,
      navigation: {
        nextEl: '.why_swiper .btn_next',
        prevEl: '.why_swiper .btn_prev',
      },
      pagination: {
        el: '.why_swiper .swiper-pagination',
        type: 'bullets',
      },
    });
  }
}
</script>